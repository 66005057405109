<template>
  <div class="top-films">
    <h2>
      <nuxt-link to="/popular">ТОП 100 аниме</nuxt-link> - выбор пользователей
    </h2>
    <Slider v-if="items.length" :videos="{ items: items }" class="anime" />
  </div>
</template>

<script>
export default {
  components: {
    Slider: () => import('~/components/Slider.vue'),
  },
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const { data } = await this.$axios.get('/api/viewpost')

      if (!data.error) {
        this.items = data
      }
    },
  },
}
</script>

<style lang="stylus">
.top-films
  margin: 20px 10px 30px
  height: 360px
</style>
