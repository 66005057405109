<template>
  <div class="themeToggle">
    <input @click="toggleTheme" type="checkbox" class="dn" id="dn" v-model="darkTheme" />
    <label for="dn" class="toggle">
      <span class="toggle__handler">
        <span class="crater crater--1"></span>
        <span class="crater crater--2"></span>
        <span class="crater crater--3"></span>
      </span>
      <span class="star star--1"></span>
      <span class="star star--2"></span>
      <span class="star star--3"></span>
      <span class="star star--4"></span>
      <span class="star star--5"></span>
      <span class="star star--6"></span>
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['theme']),
    darkTheme() {
      return this.theme === 'dark'
    }
  },
  methods: {
    toggleTheme() {
      const currentTheme = localStorage.getItem('theme')
      if (currentTheme === 'dark') {
        document.body.classList.remove('dark')
        document.body.style.backgroundColor = '#ffffff'
        localStorage.setItem('theme', 'light')
        this.$store.commit('setTheme', 'light')
      } else {
        document.body.classList.add('dark')
        document.body.style.backgroundColor = '#171c20'
        localStorage.setItem('theme', 'dark')
        this.$store.commit('setTheme', 'dark')
      }
    },
  }
}
</script>

<style lang="stylus">
.themeToggle
  position: relative
  // top: 50%
  // left: 50%
  // padding: 0 200px
  overflow: hidden
  // transform: translate3d(-50%, -50%, 0)
  input
    position: absolute
    left: -99em
  span
    display: inline-block !important
    margin: 0px !important
    padding: 0px !important
  .toggle
    cursor: pointer
    display: inline-block
    position: relative
    width: 60px
    height: 30px
    background-color: #83D8FF
    border-radius: 60px - 6
    transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95)
    // &:before
    //   content: 'AM'
    //   position: absolute
    //   left: -50px
    //   top: 15px
    //   font-size: 18px
    // &:after
    //   content: 'PM'
    //   position: absolute
    //   right: -48px
    //   top: 15px
    //   font-size: 18px
    //   color: #749ED7
  .toggle__handler
    display: inline-block
    position: relative
    z-index: 1
    top: 3px
    left: 4px
    width: 30px - 6
    height: 30px - 6
    background-color: #FFCF96
    border-radius: 30px
    box-shadow: 0 2px 6px rgba(0,0,0,.3)
    transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55)
    transform: rotate(-45deg)
    .crater
      position: absolute
      background-color: #E8CDA5
      opacity: 0
      transition: opacity 200ms ease-in-out
      border-radius: 100%
    .crater--1
      top: 12px
      left: 4px
      width: 2px
      height: 2px
    .crater--2
      top: 16px
      left: 12px
      width: 4px
      height: 4px
    .crater--3
      top: 4px
      left: 12px
      width: 6px
      height: 6px
  .star
    position: absolute
    background-color: #ffffff
    transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95)
    border-radius: 50%
  .star--1
    top: 6px
    left: 15px
    z-index: 0
    width: 20px
    height: 2px
  .star--2
    top: 11px
    left: 18px
    z-index: 1
    width: 20px
    height: 2px
  .star--3
    top: 17px
    left: 18px
    z-index: 0
    width: 20px
    height: 2px
  .star--4,
  .star--5,
  .star--6
    opacity: 0
    transition: all 300ms 0 cubic-bezier(0.445, 0.05, 0.55, 0.95)
  .star--4
    top: 6px
    left: 21px
    z-index: 0
    width: 2px
    height: 2px
    transform: translate3d(3px,0,0)
  .star--5
    top: 22px
    left: 27px
    z-index: 0
    width: 3px
    height: 3px
    transform: translate3d(3px,0,0)
  .star--6
    top: 26px
    left: 38px
    z-index: 0
    width: 2px
    height: 2px
    transform: translate3d(3px,0,0)
  input:checked + .toggle
    background-color: #749DD6
    // &:before
    //   color: #749ED7
    // &:after
    //   color: #ffffff
    .toggle__handler
      background-color: #FFE5B5
      transform: translate3d(28px, 0, 0) rotate(0)
    .crater
      opacity: 1
    .star--1
      width: 2px
      height: 2px
      transform: translate3d(4px, 0, 0)
    .star--2
      width: 4px
      height: 4px
      transform: translate3d(-4px, 0, 0)
    .star--3
      width: 2px
      height: 2px
      transform: translate3d(8px, 0, 0)
    .star--4,
    .star--5,
    .star--6
      opacity: 1
      transform: translate3d(-15px,0,0)
    .star--4
      transition: all 300ms 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95)
    .star--5
      transition: all 300ms 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95)
    .star--6
      transition: all 300ms 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95)
</style>
